"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.approveAdjustment = approveAdjustment;
exports.createAttendance = createAttendance;
exports.deleteAttendance = deleteAttendance;
exports.getAttendances = getAttendances;
exports.rejectAdjustment = rejectAdjustment;
exports.requestAdjustment = requestAdjustment;

var _request = _interopRequireDefault(require("@/utils/request"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function getAttendances(params) {
  return (0, _request.default)({
    url: `/api/v1/attendances`,
    method: 'get',
    timeout: 10500,
    // request timeout
    params
  });
}

function deleteAttendance(id) {
  return (0, _request.default)({
    url: `/api/v1/attendances/${id}`,
    method: 'delete'
  });
}

function createAttendance(data) {
  return (0, _request.default)({
    url: `/api/v1/attendances`,
    data,
    method: 'post'
  });
}

function approveAdjustment(id) {
  return (0, _request.default)({
    url: `/api/v1/attendance-adjustments/${id}/approve`,
    method: 'post'
  });
}

function rejectAdjustment(id) {
  return (0, _request.default)({
    url: `/api/v1/attendance-adjustments/${id}/reject`,
    method: 'post'
  });
}

function requestAdjustment(data) {
  return (0, _request.default)({
    url: `/api/v1/attendance-adjustments/requestAdjustment`,
    data,
    method: 'post'
  });
}