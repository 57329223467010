"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createStore = createStore;
exports.createStoreProduct = createStoreProduct;
exports.createStoreProductCombo = createStoreProductCombo;
exports.deleteStore = deleteStore;
exports.deleteStoreProduct = deleteStoreProduct;
exports.deleteStoreProductCombo = deleteStoreProductCombo;
exports.editStore = editStore;
exports.editStoreProduct = editStoreProduct;
exports.editStoreProductCombo = editStoreProductCombo;
exports.getStore = getStore;
exports.getStoreProduct = getStoreProduct;
exports.getStoreProductCombo = getStoreProductCombo;
exports.getStoreProductCombos = getStoreProductCombos;
exports.getStoreProducts = getStoreProducts;
exports.getStoreSales = getStoreSales;
exports.getStores = getStores;
exports.offlineStore = offlineStore;
exports.onlineStore = onlineStore;

var _request = _interopRequireDefault(require("@/utils/request"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function getStores(params) {
  return (0, _request.default)({
    url: `/api/v1/stores`,
    method: 'get',
    timeout: 9200,
    // request timeout
    params
  });
}

function getStore(id) {
  return (0, _request.default)({
    url: `/api/v1/stores/${id}`,
    method: 'get'
  });
}

function createStore(data) {
  return (0, _request.default)({
    url: `/api/v1/stores`,
    data,
    method: 'post'
  });
}

function editStore(data) {
  return (0, _request.default)({
    url: `/api/v1/stores/${data.id}`,
    data,
    method: 'put'
  });
}

function deleteStore(id) {
  return (0, _request.default)({
    url: `/api/v1/stores/${id}`,
    method: 'delete'
  });
}

function getStoreSales(id, params) {
  const config = {
    url: `/api/v1/stores/${id}/sales`,
    method: 'get'
  };

  if (params.signal) {
    config.signal = params.signal;
    config.timeout = 12000;
    delete params.signal;
  }

  config.params = params;
  return (0, _request.default)(config);
}

function getStoreProducts(params) {
  return (0, _request.default)({
    url: `/api/v1/store-products`,
    method: 'get',
    params
  });
}

function getStoreProduct(id) {
  return (0, _request.default)({
    url: `/api/v1/store-products/${id}`,
    method: 'get'
  });
}

function createStoreProduct(data) {
  return (0, _request.default)({
    url: `/api/v1/store-products`,
    data,
    method: 'post'
  });
}

function editStoreProduct(data) {
  return (0, _request.default)({
    url: `/api/v1/store-products/${data.uuid}`,
    data,
    method: 'put'
  });
}

function deleteStoreProduct(id) {
  return (0, _request.default)({
    url: `/api/v1/store-products/${id}`,
    method: 'delete'
  });
}

function getStoreProductCombos(params) {
  return (0, _request.default)({
    url: `/api/v1/store-product-raw-materials`,
    method: 'get',
    params
  });
}

function getStoreProductCombo(id) {
  return (0, _request.default)({
    url: `/api/v1/store-product-raw-materials/${id}`,
    method: 'get'
  });
}

function createStoreProductCombo(data) {
  return (0, _request.default)({
    url: `/api/v1/store-product-raw-materials`,
    data,
    method: 'post'
  });
}

function editStoreProductCombo(data) {
  return (0, _request.default)({
    url: `/api/v1/store-product-raw-materials/${data.uuid}`,
    data,
    method: 'put'
  });
}

function deleteStoreProductCombo(id) {
  return (0, _request.default)({
    url: `/api/v1/store-product-raw-materials/${id}`,
    method: 'delete'
  });
}

function onlineStore(id) {
  return (0, _request.default)({
    url: `/api/v1/stores/${id}/online`,
    method: 'post'
  });
}

function offlineStore(id) {
  return (0, _request.default)({
    url: `/api/v1/stores/${id}/offline`,
    method: 'post'
  });
}